import React from "react"

import { Link } from 'gatsby';

import Layout from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";
import CenteredParagraph from "../components/elements/centered-paragraph";
import ProductThumbs from "../components/elements/product-thumbs";
import VideoRow from "../components/elements/video-row";
import Video from "../components/elements/video";
import MicroSure from "../components/elements/micro-sure";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container id="home-hero">
      <h2>
        Protect yourself<br />
        and your loved ones
      </h2>
      <p>
        Hands sanitized?<br />
        Surfaces disinfected?<br />
        Laundry protected?<br />
        Are you <b>microSURE?</b>
      </p>
    </Container>
    <Container>
      <CenteredParagraph>
        <p>
          Unlike toxic solutions which only provide limited‐time protection and are alcohol base,<br />
          the <MicroSure /> line of antimicrobial solutions is safely designed to mechanically kill<br />
          viruses and provide long‐lasting protection. The <MicroSure /> line of antimicrobial<br />
          solutions is FDA registered and includes 8‐HOUR DEFENSE hand sanitizer, <MicroSure /><br />
          all‐purpose surface disinfectant, and <MicroSure /> all‐purpose laundry detergent.</p>
        <p>
          <br />
          <Link to="/products" className="button">Learn More</Link>
          <br />
          <br />
        </p>
      </CenteredParagraph>
      <ProductThumbs />
    </Container>
    <Container id="home-video">
      <VideoRow>
        <div className="left">
          <Video title="Video" videoID="HtZNk60ntKw" />
        </div>
        <div className="right">
          <h3>8 Hour Defense Hand Sanitizer</h3>
          <p>Killing more than 99.99% of deadly viruses, the <MicroSure /> line of antimicrobial solutions is your persistent safeguard against most illness‐causing germs. <MicroSure />’s revolutionary nano‐technology creates a barrier of crystal‐sized killing spikes that attaches to hands and surfaces to protect your health for long periods of time.</p>
          <p><Link to="/how-to" className="button">Learn More</Link></p>
        </div>
      </VideoRow>
    </Container>
  </Layout>
)

export default IndexPage
